/* eslint-disable camelcase */
import * as amplitude from '@amplitude/analytics-browser'

window.amplitude = amplitude

const {
  apiKey,
  deviceId,
  userId,
  userProperties,
} = window._amplitude_init_data

window.amplitude.init(apiKey, userId, {
  defaultTracking: false,
  deviceId,
})

if (Object.keys(userProperties).length > 0) {
  const identifyEvent = new amplitude.Identify()
  Object.entries(userProperties).forEach(([key, value]) => {
    identifyEvent.set(key, value)
  })
  window.amplitude.identify(identifyEvent)
}

// Amplitude Event 對照文件：
//   - https://www.notion.so/statementdog/Amplitude-data-eb4d500f01e043719277ae83a6abde87

const sendAmplitudeEvent = (eventType, eventProperties, eventCallback = null) => {
  const isSignedIn = window._statementdog_global_variable_.currentUser.is_signed_in

  // 需要同時處理：`app/views/common/_amplitude_analytics.html.erb`
  if (!isSignedIn) {
    return
  }

  window.amplitude.track(eventType, eventProperties).promise.then(() => {
    if (typeof eventCallback === 'function') {
      eventCallback()
    }
  })
}

/**
 * 在 Amplitude 尚未載入之前，會將事件先放到 queue 裡面
 * 因為這支程式在 app/views/common/_amplitude_analytics 載入設定為 defer
 * 有可能在主程式執行時 Amplitude 尚未載入，這邊處理在這隻檔案載入前發送的事件
 */

window.amplitudeEventQueue.forEach((event) => {
  sendAmplitudeEvent(event.eventName, event.eventProperties, event.eventCallback)
})
window.sendAmplitudeEvent = sendAmplitudeEvent

window.amplitudeEvents.pageViewed(window._amplitude_init_data.pageViewedEvent)
